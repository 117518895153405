import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function OrderStatusCOD() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [orderId, setOrderId] = useState("");
  const [paymentId, setPaymentId] = useState("");

  useEffect(() => {
    var orderData = JSON.parse(localStorage.getItem("OrderDetails"));
    if (orderData) {
      setOrderId(orderData.OrderId);
    } else {
      history.push("/home");
    }
  }, []);

  return (
    <div className="col-md-9 col-md-push-3">
      <div className="checkout-confirm">
        <img src="assets/images/okay.png" alt="Okay" />
        <h3>Order Placed Successfully.</h3>
        <h4>Thank you for your order</h4>
        <p>
          Order ID: <strong>{orderId}</strong>{" "}
        </p>

        <div className="clearfix form-action">
          <Link
            to="/placed-orders"
            type="submit"
            className="btn btn-accent min-width"
          >
            View Orders
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OrderStatusCOD;
