import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { SHA256 } from "crypto-js";
import { Link } from "react-router-dom";

function OrderStatus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [phonpePaymentStatus, setPhonpePaymentStatus] = useState();

  useEffect(() => {
    config();
  }, []);

  const config = async () => {
    var OnlineOrderDetails = JSON.parse(
      localStorage.getItem("OnlineOrderDetails")
    );
    var PaymentDetails = JSON.parse(localStorage.getItem("PaymentDetails"));
    console.log("OnlineOrderDetails", OnlineOrderDetails);
    console.log("PaymentDetails", PaymentDetails);
    if (PaymentDetails) {
      checkPaymentStatus(PaymentDetails, OnlineOrderDetails);
    } else {
      history.push("/categories");
    }
  };

  const checkPaymentStatus = (data, data1) => {
    setIsLoading(true);
    var saltKey = "0038a2ff-6756-4b1b-882d-6bac32987fbf";
    // const base64 = window.btoa(JSON.stringify(data));
    var saltIndex = 1;
    var string =
      "/pg/v1/status/" +
      data.merchantId +
      "/" +
      data.merchantTransactionId +
      "" +
      saltKey;
    var sha256 = SHA256(string).toString();
    var xVerify = sha256 + "###" + saltIndex;
    Service.checkPaymentStatus(
      xVerify,
      data.merchantId,
      data.merchantTransactionId
    )
      .then((res) => {
        console.log(res.data);
        setPhonpePaymentStatus(res.data);
        if (res.data?.data?.state === "COMPLETED") {
          setIsCompleted(true);
          setIsPending(false);
          setIsFailed(false);
          saveOrder(res.data, data1);
          setIsLoading(false);
        } else if (res.data?.data?.state === "PENDING") {
          setIsCompleted(false);
          setIsPending(true);
          setIsFailed(false);
          paymentFailed(res.data, data1);
          setIsLoading(false);
        } else {
          setIsCompleted(false);
          setIsPending(false);
          setIsFailed(true);
          paymentFailed(res.data, data1);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const saveOrder = (payDetails, onlineOrderDetails) => {
    setIsLoading(true);
    console.log("dd", onlineOrderDetails);
    onlineOrderDetails.PaymentStatus = "Paid";
    onlineOrderDetails.Code = payDetails.code;
    onlineOrderDetails.MerchantTransactionId = payDetails?.data
      ?.merchantTransactionId
      ? payDetails?.data?.merchantTransactionId
      : "";
    onlineOrderDetails.TransactionId = payDetails?.data?.transactionId
      ? payDetails?.data?.transactionId
      : "";
    Service.placeOrder(onlineOrderDetails).then((res) => {
      if (res.data.success) {
        localStorage.removeItem("OnlineOrderDetails");
        localStorage.removeItem("PaymentDetails");
      }
      setIsLoading(false);
    });
  };

  const paymentFailed = (payDetails, onlineOrderDetails) => {
    setIsLoading(true);

    onlineOrderDetails.Success = payDetails.success;
    onlineOrderDetails.Code = payDetails.code;
    onlineOrderDetails.MerchantId = payDetails?.data.merchantId
      ? payDetails?.data.merchantId
      : "";
    onlineOrderDetails.MerchantTransactionId = payDetails?.data
      .merchantTransactionId
      ? payDetails?.data.merchantTransactionId
      : "";
    onlineOrderDetails.TransactionId = payDetails?.data?.transactionId
      ? payDetails?.data?.transactionId
      : "";
    onlineOrderDetails.Amount = payDetails?.data?.amount;
    console.log("onlineOrderDetails-->", onlineOrderDetails);
    Service.saveFailedOrder(onlineOrderDetails).then((res) => {
      localStorage.removeItem("OnlineOrderDetails");
      localStorage.removeItem("PaymentDetails");
      setIsLoading(false);
    });
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      {isCompleted && (
        <div className="checkout-confirm">
          <img src="assets/images/okay.png" alt="Okay" />
          <h3>Payment Complete</h3>
          <h4>{phonpePaymentStatus?.message}</h4>
          <p>
            Merchant Trans. ID:{" "}
            <strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}
          </p>
          <p>
            Transaction ID:{" "}
            <strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}
          </p>

          <div className="clearfix form-action">
            <Link
              to="/placed-orders"
              type="submit"
              className="btn btn-accent min-width"
            >
              View Orders
            </Link>
          </div>
        </div>
      )}

      {isPending && (
        <div className="checkout-confirm">
          <img src="assets/images/info.png" alt="Okay" />
          <h3>Payment Pending.</h3>
          <h4>
            {phonpePaymentStatus?.code} | {phonpePaymentStatus?.message}
          </h4>
          <p>
            Merchant Trans. ID:{" "}
            <strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}
          </p>
          <p>
            Transaction ID:{" "}
            <strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}
          </p>

          <div className="clearfix form-action">
            <Link
              to="/checkout"
              type="submit"
              className="btn btn-accent min-width"
            >
              Goto Checkout
            </Link>
          </div>
        </div>
      )}

      {isFailed && (
        <div className="checkout-confirm">
          <img src="assets/images/info.png" alt="Okay" />
          <h3>Payment Failed.</h3>
          <h4>
            {phonpePaymentStatus?.code} | {phonpePaymentStatus?.message}
          </h4>
          <p>
            Merchant Trans. ID:{" "}
            <strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}
          </p>
          <p>
            Transaction ID:{" "}
            <strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}
          </p>

          <div className="clearfix form-action">
            <Link
              to="/checkout"
              type="submit"
              className="btn btn-accent min-width"
            >
              Place Order
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderStatus;
