import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";

function ManageProducts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [sCatId, setSCatId] = useState(0);

  useEffect(() => {
    loadCatData();
  }, []);

  const loadCatData = () => {
    setIsLoading(true);
    Service.getAllCategories().then((res) => {
      setCatData(res.data);
      setIsLoading(false);
    });
  };

  const loadSubCatData = (e) => {
    if (e.target.value) {
      setIsLoading(true);
      Service.getSubcategories(e.target.value).then((res) => {
        setSubCatData(res.data);
        setIsLoading(false);
      });
    }
  };

  const loadProductData = (e) => {
    setSCatId(e.target.value);
    loadProductDataBySubCat(e.target.value);
  };

  const loadProductDataBySubCat = (SCatId) => {
    setIsLoading(true);
    Service.getProducts(SCatId).then((res) => {
      setProductData(res.data);
      setIsLoading(false);
    });
  };

  const addNewProduct = () => {
    localStorage.setItem("PageName", "Add New Product");
    history.push("/admin/dash/product");
  };

  const deleteRecord = (item) => {
    setIsLoading(true);
    Service.deleteProduct(item.ProdId).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        loadProductDataBySubCat(sCatId);
      }
      setIsLoading(false);
    });
  };

  const editRecord = (item) => {
    localStorage.setItem("PageName", "Edit Product");
    localStorage.setItem("ProductDataForEdit", JSON.stringify(item));
    history.push("/admin/dash/product");
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header">
        <h1>Manage Products</h1>
        <hr />
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <label>Select Category*</label>
            <select
              type="text"
              className="form-control"
              onChange={loadSubCatData}
            >
              <option value="">Select Category</option>
              {catData.map((item, i) => (
                <option key={i} value={item.CatId}>
                  {item.CatName}
                </option>
              ))}
            </select>
          </div>
          {/* End .form-group */}
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>Select Sub Category*</label>
            <select
              type="text"
              className="form-control"
              onChange={loadProductData}
            >
              <option value="">Select Sub-Category</option>
              {subCatData.map((item, index) => (
                <option key={index} value={item.SCatId}>
                  {item.SCatName}
                </option>
              ))}
            </select>
          </div>
          {/* End .form-group */}
        </div>
        <div className="col-sm-4 my-auto">
          <div className="text-right">
            <a
              onClick={() => addNewProduct()}
              className="btn btn-primary min-width"
              style={{ marginRight: "1rem" }}
            >
              Add New Product
            </a>
          </div>
        </div>
      </div>

      {/* End .page-header */}

      {showMsg == true ? (
        <div className={msgClass} role="alert">
          <strong>{msgText}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Original Price</th>
              <th>Discounted Price</th>
              <th>Trending</th>
              <th>Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productData.map((item, index) => (
              <tr key={index}>
                <td className="price-col">{item.PName}</td>
                <td className="price-col">{item.OriginalPrice}</td>
                <td className="price-col">{item.DiscountedPrice}</td>
                <td className="price-col">{item.Trending}</td>
                <td className="delete-col">
                  <i
                    className="fa fa-pencil fa-lg"
                    style={{ marginRight: "2rem" }}
                    aria-hidden="true"
                    onClick={() => editRecord(item)}
                  ></i>
                  <i
                    className="fa fa-trash fa-lg"
                    aria-hidden="true"
                    onClick={() => {
                      const confirmBox = window.confirm(
                        "Do you want to delete this product..?"
                      );
                      if (confirmBox === true) {
                        deleteRecord(item);
                      }
                    }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageProducts;
