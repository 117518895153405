import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserProtectedRoute from "../service/UserProtectedRoute";
import AdminProtectedRoute from "../service/AdminProtectedRoute";

//import Site from "../site/Site";
import SiteContainer from "../containers/SiteContainer";
//import User from '../user/User'
import Admin from "../admin/Admin";

// site views
import Home from "../site/Home";
import About from "../site/About";
import Contact from "../site/Contact";
import UserLogin from "../site/UserLogin";
import CreateAccount from "../site/CreateAccount";
import VerifyOtp from "../site/VerifyOtp";
import Welcome from "../site/Welcome";
import Categories from "../site/Categories";
import Subcategories from "../site/Subcategories";
import Products from "../site/Products";
import SearchResult from "../site/SearchResult";
// import ProductDetails from "../site/ProductDetails";
import ProductDetailsConatiner from "../containers/ProductDetailsConatiner";
import AdminLogin from "../site/AdminLogin";
import ForgotPassword from "../site/ForgotPassword";
import GuestAccount from "../site/GuestAccount";

// user Views
import UserProfile from "../user/UserProfile";
// import Cart from "../user/Cart";
import CartContainer from "../containers/CartContainer";
import ResetPass from "../user/ResetPass";
import CheckoutContainer from "../containers/CheckoutContainer";
import OrderStatusCOD from "../user/OrderStatusCOD";
import OrderStatus from "../user/OrderStatus";
import Orders from "../user/Orders";

// admin Views
import AdminProfile from "../admin/AdminProfile";
import UpdateLogo from "../admin/UpdateLogo";
import ManageCategories from "../admin/ManageCategories";
import CategoryForm from "../admin/CategoryForm";
import SubCategoryForm from "../admin/SubCategoryForm";
import ManageSubCategories from "../admin/ManageSubCategories";
import ManageProducts from "../admin/ManageProducts";
import ProductForm from "../admin/ProductForm";
import ManageColors from "../admin/ManageColors";
import ColorForm from "../admin/ColorForm";
import ManageImages from "../admin/ManageImages";
import ImagesForm from "../admin/ImagesForm";
import ManageDescription from "../admin/ManageDescription";
import DescriptionForm from "../admin/DescriptionForm";
import ManageInformations from "../admin/ManageInformations";
import InformationForm from "../admin/InformationForm";
import ManageSizes from "../admin/ManageSizes";
import SizeForm from "../admin/SizeForm";
import ManageSlider from "../admin/ManageSlider";
import SliderForm from "../admin/SliderForm";
import PlacedOrders from "../admin/PlacedOrders";
import OrderDetails from "../admin/OrderDetails";
import ResetPassword from "../admin/ResetPassword";
import ManageAbout from "../admin/ManageAbout";
import AboutForm from "../admin/AboutForm";

function Routers() {
  return (
    <Router>
      <Switch>
        <Route path="/:path?/:path?" exact>
          <SiteContainer>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/login" component={UserLogin} />
              <Route path="/admin" component={AdminLogin} />
              <Route path="/create" component={CreateAccount} />
              <Route path="/verify-otp" component={VerifyOtp} />
              <Route path="/welcome" component={Welcome} />
              <Route path="/categories" component={Categories} />
              <Route path="/sub-categories" component={Subcategories} />
              <Route path="/products/:subCatId" component={Products} />
              <Route path="/search-result" component={SearchResult} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/guest-checkout" component={GuestAccount} />
              {/* <Route path="/product-details" component={ProductDetails} /> */}
              <Route
                path="/product-details/:id"
                component={ProductDetailsConatiner}
              />
              <UserProtectedRoute path="/profile" component={UserProfile} />
              <UserProtectedRoute path="/cart" component={CartContainer} />
              <UserProtectedRoute path="/reset-pass" component={ResetPass} />
              <UserProtectedRoute
                path="/checkout"
                component={CheckoutContainer}
              />
              <UserProtectedRoute
                path="/cod-order-status"
                component={OrderStatusCOD}
              />
              <UserProtectedRoute
                path="/online-order-status"
                component={OrderStatus}
              />
              <UserProtectedRoute path="/placed-orders" component={Orders} />
            </Switch>
          </SiteContainer>
        </Route>

        {/* <Route path='/user/:path?' exact>
        <User>
          <Switch>
            <Route path='/user/profile' component={UserProfile} />
          </Switch>
        </User>
      </Route> */}

        <Route path="/admin/dash/:path?/:path?" exact>
          <Admin>
            <Switch>
              <AdminProtectedRoute
                path="/admin/dash/profile"
                component={AdminProfile}
              />
              <AdminProtectedRoute
                path="/admin/dash/reset-password"
                component={ResetPassword}
              />
              <AdminProtectedRoute
                path="/admin/dash/update-logo"
                component={UpdateLogo}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-categories"
                component={ManageCategories}
              />
              <AdminProtectedRoute
                path="/admin/dash/category"
                component={CategoryForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-sub-categories"
                component={ManageSubCategories}
              />
              <AdminProtectedRoute
                path="/admin/dash/sub-category"
                component={SubCategoryForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-products"
                component={ManageProducts}
              />
              <AdminProtectedRoute
                path="/admin/dash/product"
                component={ProductForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-colors"
                component={ManageColors}
              />
              <AdminProtectedRoute
                path="/admin/dash/color"
                component={ColorForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-images"
                component={ManageImages}
              />
              <AdminProtectedRoute
                path="/admin/dash/image"
                component={ImagesForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-descriptions"
                component={ManageDescription}
              />
              <AdminProtectedRoute
                path="/admin/dash/descriptions"
                component={DescriptionForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-about-us"
                component={ManageAbout}
              />
              <AdminProtectedRoute
                path="/admin/dash/about-us"
                component={AboutForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-informations"
                component={ManageInformations}
              />
              <AdminProtectedRoute
                path="/admin/dash/informations"
                component={InformationForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-sizes"
                component={ManageSizes}
              />
              <AdminProtectedRoute
                path="/admin/dash/sizes"
                component={SizeForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/manage-slider"
                component={ManageSlider}
              />
              <AdminProtectedRoute
                path="/admin/dash/slider"
                component={SliderForm}
              />
              <AdminProtectedRoute
                path="/admin/dash/orders"
                component={PlacedOrders}
              />
              <AdminProtectedRoute
                path="/admin/dash/orders-details/:id"
                component={OrderDetails}
              />
            </Switch>
          </Admin>
        </Route>
      </Switch>
    </Router>
  );
}

export default Routers;
