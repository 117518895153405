import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";

function ResetPassword() {
  const { register, handleSubmit, errors, getValues, reset, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [passClass, setPassClass] = useState("glyphicon glyphicon-eye-open");
  const [passType, setPassType] = useState("password");
  const [passClass2, setPassClass2] = useState("glyphicon glyphicon-eye-open");
  const [passType2, setPassType2] = useState("password");
  const [passClass3, setPassClass3] = useState("glyphicon glyphicon-eye-open");
  const [passType3, setPassType3] = useState("password");

  const onSubmit = (data) => {
    setIsLoading(true);
    Service.resetAdminPassword(data).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        reset();
      } else if (res.data.warning) {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  const togglePassword = () => {
    if (passType == "password") {
      setPassClass("glyphicon glyphicon-eye-close");
      setPassType("text");
    } else {
      setPassClass("glyphicon glyphicon-eye-open");
      setPassType("password");
    }
  };

  const togglePassword2 = () => {
    if (passType2 == "password") {
      setPassClass2("glyphicon glyphicon-eye-close");
      setPassType2("text");
    } else {
      setPassClass2("glyphicon glyphicon-eye-open");
      setPassType2("password");
    }
  };

  const togglePassword3 = () => {
    if (passType3 == "password") {
      setPassClass3("glyphicon glyphicon-eye-close");
      setPassType3("text");
    } else {
      setPassClass3("glyphicon glyphicon-eye-open");
      setPassType3("password");
    }
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header">
        <h1>Reset your account password</h1>
        <hr />
      </div>
      {/* End .page-header */}

      {showMsg == true ? (
        <div className={msgClass} role="alert">
          <strong>{msgText}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}

      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="signup-form"
      >
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Current Password*</label>
              <div class="input-group">
                <input
                  type={passType}
                  className="form-control"
                  ref={register({
                    required: "Enter current password.",
                  })}
                  name="CurrentPass"
                />
                <span
                  onClick={togglePassword}
                  className="btn btn-default reveal border"
                >
                  <i className={passClass}></i>
                </span>
              </div>
              {errors.CurrentPass && (
                <span className="text-danger">
                  {errors.CurrentPass.message}
                </span>
              )}
            </div>
            {/* End .form-group */}
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>New Password*</label>
              <div class="input-group">
                <input
                  type={passType2}
                  className="form-control"
                  ref={register({
                    required: "Enter new password.",
                  })}
                  name="Pass"
                />
                <span
                  onClick={togglePassword2}
                  className="btn btn-default reveal border"
                >
                  <i className={passClass2}></i>
                </span>
              </div>
              {errors.Pass && (
                <span className="text-danger">{errors.Pass.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>Confirm Password*</label>
              <div class="input-group">
                <input
                  type={passType3}
                  className="form-control"
                  ref={register({
                    required: "Enter confirm password.",
                    validate: (value) =>
                      value === getValues("Pass") || "Password does not match.",
                  })}
                  name="ConfirmPass"
                />
                <span
                  onClick={togglePassword3}
                  className="btn btn-default reveal border"
                >
                  <i className={passClass3}></i>
                </span>
              </div>
              {errors.ConfirmPass && (
                <span className="text-danger">
                  {errors.ConfirmPass.message}
                </span>
              )}
            </div>
            {/* End .form-group */}
          </div>

          <div className="col-sm-4"></div>
        </div>
        {/* End .row */}

        <div className="clearfix form-action">
          <input
            type="submit"
            className="btn btn-primary min-width"
            value="Submit"
          />
        </div>
        {/* End .form-action */}
      </form>
    </div>
  );
}

export default ResetPassword;
