import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function ForgotPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");

  const onSubmit = (data) => {
    setIsLoading(true);
    Service.forgotPassword(data)
      .then((res) => {
        if (res.data.success) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible");
          setMsgText(res.data.success);
          reset();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header text-center">
        <h1>Forgot Password</h1>
        <p>Verify Your Account</p>
      </div>
      {/* End .page-header */}

      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="signin-form"
      >
        {showMsg == true ? (
          <div className={msgClass} role="alert">
            <strong>{msgText}</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}
        <div className="form-group">
          <label>Enter Registered Email ID*</label>
          <input
            type="text"
            className="form-control"
            ref={register({
              required: "Enter email id.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter valid email address.",
              },
            })}
            name="Email"
          />
          {errors.Email && (
            <span className="text-danger">{errors.Email.message}</span>
          )}
        </div>
        {/* End .form-group */}

        <div className="clearfix form-action">
          <input
            type="submit"
            className="btn btn-accent pull-right  min-width"
            value="Submit"
          />
        </div>
        {/* End .form-action */}
      </form>
    </div>
  );
}

export default ForgotPassword;
