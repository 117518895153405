import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function VerifyOtp() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setMinutes(2);
    setSeconds(59);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const onSubmit = (data) => {
    setIsLoading(true);
    data.UserId = localStorage.getItem("UserId");
    Service.verifyOtp(data).then((res) => {
      if (res.data.success) {
        history.push("/welcome");
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  const resendOtp = () => {
    setIsLoading(true);
    var data = { UserId: localStorage.getItem("UserId") };
    Service.resendOtp(data).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        setMinutes(2);
        setSeconds(59);
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header text-center">
        <h1>Verify OTP</h1>
        {/* <p>Enter OTP For Account Verification</p> */}
      </div>
      {/* End .page-header */}
      <h4 className="text-center">
        OTP has been sent on your registered email..!{" "}
      </h4>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="signin-form"
      >
        {showMsg == true ? (
          <div className={msgClass} role="alert">
            <strong>{msgText}</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}
        <div className="form-group">
          <label>Enter OTP*</label>
          <input
            className="form-control"
            ref={register({
              required: "Enter OTP.",
            })}
            name="Otp"
          />
        </div>
        <div className="clearfix ">
          <div className="checkbox pull-right">
            {seconds > 0 || minutes > 0 ? (
              <span>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            ) : (
              <span>Didn't recieve code?</span>
            )}
          </div>
        </div>
        {/* End .form-group */}
        <div className="clearfix form-action">
          <input
            type="submit"
            className="btn btn-accent pull-left min-width"
            value="Verify"
          />

          <input
            disabled={seconds > 0 || minutes > 0}
            onClick={() => resendOtp()}
            type="submit"
            className="btn btn-primary pull-right min-width"
            value="Resend OTP"
          />
        </div>
      </form>
    </div>
  );
}

export default VerifyOtp;
