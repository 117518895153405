import React, { useState, useEffect } from "react";
import Service from "../service/Service";

export const Print = React.forwardRef((props, ref) => {
  useEffect(() => {
    loadData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState(false);

  const loadData = () => {
    setIsLoading(true);
    Service.getOrgData().then((res) => {
      setProfileData(res.data);
      setIsLoading(false);
    });
  };
  return (
    <div ref={ref} className="print-out-m">
      {props.data.length > 0 && (
        <div>
          <p className="fw-bold display-4 text-center text-decoration-underline">
            {profileData[0].OrgName}
          </p>

          <div className="row">
            <div className="col-6 border-start border-3">
              <p className="h3">Order ID: {props.data[0].OrderId}</p>
              <p className="h3 mb-0">
                Order Date:{" "}
                {new Date(props.data[0].orderDate).toLocaleDateString()}
              </p>
            </div>
            <div className="col-6 border-start border-3">
              <p className="h3">Invoice No.: {props.data[0].OrderId}</p>
              <p className="h3 mb-0">
                Invoice Date:{" "}
                {new Date(props.data[0].orderDate).toLocaleDateString()}
              </p>
            </div>
            {/* <div className="col-4 border-start border-3">
              <p className="h3">GST No.: {props.data.OrderId}</p>
              <p className="h3 mb-0">PAN No.: DHCKW6745J</p>
            </div> */}
          </div>

          <div className="row mt-5">
            <div className="col-6 border-start border-3">
              <h4 className="mb-0">Sold By:</h4> <br />
              <p className="mb-0">{profileData[0].OrgName},</p>
              <p className="mb-0">{profileData[0].Address}</p>
              <p className="mb-0">Contact: {profileData[0].Contact}</p>
              <p className="mb-0">Email: {profileData[0].Email}</p>
            </div>
            <div className="col-6 border-start border-3">
              <h4 className="mb-0">Billing / Shipping Address:</h4> <br />
              <p className="mb-0">
                {props.data[0].Address1 +
                  ", " +
                  props.data[0].Address2 +
                  ", " +
                  props.data[0].City +
                  ", " +
                  props.data[0].State +
                  ", " +
                  props.data[0].Pincode}
              </p>
              <p className="mb-0">Phone No.: {props.data[0].Contact},</p>
              <p className="mb-0">Email ID: {props.data[0].Email}</p>
            </div>
          </div>

          <div className="mt-5">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">SR.NO</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.data.map((item, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.PName}</td>
                    <td>{item.Qty}</td>
                    <td>{item.Price}</td>
                    <td>₹ {item.Total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row mt-5">
            <div className="col-10">
              <h6 className="mb-0 pull-right">Other Charges :</h6> <br />
            </div>
            <div className="col-2">
              <h6 className="mb-0 fw-bold">₹ {props.data[0].OtherCharges}</h6>{" "}
              <br />
            </div>
            <div className="col-10 ">
              <h6 className="mb-0 pull-right">Nett Total :</h6> <br />
            </div>
            <div className="col-2">
              <h6 className="mb-0 fw-bold">₹ {props.data[0].NetTotal}</h6>{" "}
              <br />
            </div>
            <div className="col-12 border-bottom border-3">
              <hr />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
